import React, {
  SetStateAction,
  Dispatch,
  ReactNode,
  useState,
  createContext,
} from 'react';

import { Payment, paymentInitialState } from 'src/hooks/usePayment';


type PaymentContextType = {
  payment: Payment
  setPayment: Dispatch<SetStateAction<Payment>>
}

type PaymentContextProviderProps = {
  children: ReactNode
}

export const PaymentContext = createContext({} as PaymentContextType);

export const PaymentContextProvider = ({ children }: PaymentContextProviderProps) => {
  const [payment, setPayment] = useState<Payment>(paymentInitialState);
  return (
    <PaymentContext.Provider value={{ payment, setPayment }}>
      {children}
    </PaymentContext.Provider>
  );
};
