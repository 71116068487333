import React, {
  SetStateAction,
  Dispatch,
  ReactNode,
  useState,
  createContext,
} from 'react';

interface VisitorType {
  country?: string;
  language?: string;
}

type VisitorContextType = {
  visitor?: VisitorType;
  setVisitor: Dispatch<SetStateAction<VisitorType | undefined>>
}

type VisitorContextProviderProps = {
  children: ReactNode
}

export const VisitorContext = createContext({} as VisitorContextType);

export const VisitorContextProvider = ({ children }: VisitorContextProviderProps) => {
  const [visitor, setVisitor] = useState<VisitorType | undefined>();
  return (
    <VisitorContext.Provider value={{ visitor, setVisitor }}>
      {children}
    </VisitorContext.Provider>
  );
};
