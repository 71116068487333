export const regexPattern = {
  MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
  VISA: /^4[0-9]{2,}$/,
  AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
};

export enum CVCMaxCount {
  MASTERCARD = 3,
  VISA = 3,
  AMERICAN_EXPRESS = 4,
}

export enum CardsNumberCount {
  MASTERCARD = 16,
  VISA = 16,
  AMERICAN_EXPRESS = 15,
}

export enum CardType {
  MASTERCARD = 'mastercard',
  VISA = 'visa',
  AMERICAN_EXPRESS = 'amex',
}

export const useCardType = (value: string) => {
  const finalValue = value.replace(/[^\d]/g, '');

  if (finalValue.match(regexPattern.MASTERCARD)) {
    return {
      cardType: CardType.MASTERCARD,
      maxLength: CardsNumberCount.MASTERCARD,
      cvcMaxLength: CVCMaxCount.MASTERCARD,
    };
  }

  if (finalValue.match(regexPattern.VISA)) {
    return {
      cardType: CardType.VISA,
      maxLength: CardsNumberCount.VISA,
      cvcMaxLength: CVCMaxCount.VISA,
    };
  }

  if (finalValue.match(regexPattern.AMERICAN_EXPRESS)) {
    return {
      cardType: CardType.AMERICAN_EXPRESS,
      maxLength: CardsNumberCount.AMERICAN_EXPRESS,
      cvcMaxLength: CVCMaxCount.AMERICAN_EXPRESS,
    };
  }

  return {
    cardType: '',
    maxLength: CardsNumberCount.MASTERCARD,
    cvcMaxLength: CVCMaxCount.MASTERCARD,
  };
};
