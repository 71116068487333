import React, { useContext, useState } from 'react';
import { PaymentContext } from 'src/context/PaymentContext';

import {
  TranslationContext,
  TranslationContextType,
  TranslationType,
} from 'src/context/TranslationContext';
import { retrieveTranslation } from 'src/languages';
import { language as lang, SUPPORTED_LANGUAGES } from 'src/languages/language';

export const markAsLoaded = (items: TranslationType): TranslationType => ({ ...items, __loaded__: '1' });

export const setTranslation = async (locale: string, translationContext: TranslationContextType) => {
  const i18nItems = await retrieveTranslation(locale as SUPPORTED_LANGUAGES);
  translationContext.setTranslations(markAsLoaded(i18nItems));
};

export const LanguageSwitcher: React.FC = () => {
  const { payment } = useContext(PaymentContext);
  const translationContext = useContext(TranslationContext);
  const initialLanguage = lang.detected;
  const [language, setLanguage] = useState<string>(initialLanguage);

  if (language === payment.lang_code) {
    return null;
  }

  const onClick = (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();
    setTranslation(payment.lang_code, translationContext);
    setLanguage(payment.lang_code);
  };

  return (
    <p style={{ display: 'none' }}>
      Change language to <a href="" onClick={onClick}>{payment.lang_code}</a>
    </p>
  );
};
