/* eslint-disable max-len */
import React from 'react';
import { useContextDetails } from 'src/hooks/useContextDetails';
import { PayformVersions } from 'src/hooks/usePayment';

interface Props {
  oldIcon: JSX.Element,
  newIcon: JSX.Element,
}

export const StatusIcon: React.FC<Props> = ({
  oldIcon,
  newIcon,
}) => {
  const { paymentDetails, statusConfig } = useContextDetails();
  const version = paymentDetails.pay_form_version !== PayformVersions.OLD
    ? paymentDetails.pay_form_version
    : statusConfig.pay_form_version;

  if (version === PayformVersions.CLASSIC) {
    return oldIcon;
  }

  if (version === PayformVersions.MODERN) {
    return newIcon;
  }

  return oldIcon;
};
