import { TranslationValueType } from 'src/context/TranslationContext';
import * as fallbackLanguage from 'src/languages/en.json';
import { replace } from 'src/utils/string';
import {
  language,
  SUPPORTED_LANGUAGES,
  DIRECTIONS,
} from './language';

export const importLanguage = (lang: string) => async () => import(`./${lang}.json`);

interface TranslationContextType {
  [key: string]: string;
}

export const joinTranslations = (data: TranslationValueType): string => Array.isArray(data) ? data.join(' ') : data;

export const i18n = (translation : TranslationValueType, replaceTo?: TranslationContextType): string => {
  let template = joinTranslations(translation);
  if (!replaceTo) {
    return template;
  }

  for (const [key, value] of Object.entries(replaceTo)) {
    template = template ? replace(template, String(`[${String(key)}]`), value) : template;
  }
  return template;
};

interface Trans {
  [key: string]: () => Promise<TranslationContextType>;
}

export const Translations: Trans = {};
language.supportedCodes.map((lang: string) => Translations[lang] = importLanguage(lang));

const addBodyClass = (direction: DIRECTIONS) => {
  document.body.classList.remove(DIRECTIONS.LTR);
  document.body.classList.remove(DIRECTIONS.RTL);
  document.body.classList.add(direction);
};

export const retrieveTranslation = async (locale?: SUPPORTED_LANGUAGES): Promise<TranslationContextType> => {
  const myLocale = (locale || language.fromStorage || language.default) as SUPPORTED_LANGUAGES;
  const fallbackResponse = fallbackLanguage as unknown as TranslationContextType;
  fallbackResponse.__direction__ = language.getDirection(SUPPORTED_LANGUAGES.EN);
  fallbackResponse.__language__ = SUPPORTED_LANGUAGES.EN;

  const defaultResponse = async () => new Promise<TranslationContextType>(() => fallbackResponse);

  if (myLocale === SUPPORTED_LANGUAGES.EN) {
    return fallbackResponse;
  }

  const langNamespace = Translations[myLocale];
  if (typeof langNamespace !== 'function') {
    return fallbackResponse;
  }

  try {
    const i18nItems = await langNamespace();
    const direction = language.getDirection(myLocale);
    i18nItems.__direction__ = direction;
    i18nItems.__language__ = myLocale;
    addBodyClass(direction);
    return i18nItems;
  } catch (e) {
    console.info(e);
    return await defaultResponse();
  }

};
