import { useContextDetails } from 'src/hooks/useContextDetails';
import { Payment } from 'src/hooks/usePayment';
import { isDemoPayment } from 'src/demo/utils';

interface Props {
  newCardMode: boolean;
}

interface Response {
  rememberCard: boolean;
  savedCardDigits: string;
  showRememberCard: boolean;
  showCvc: boolean;
  savedCardType: string;
}

export const hasSavedCard = (payment: Payment): boolean => !!Object.keys(payment.saved_card).length;

export const shouldShowCvC = (payment: Payment, newCardMode: boolean, isDemo: boolean): boolean => {
  if (isDemo) {
    return true;
  }

  if (!payment.is_apm) {
    if (newCardMode) {
      if (!(payment.is_moto || payment.is_oct)) {
        return true;
      }
    }
  }

  if (hasSavedCard(payment)) {
    if (!(payment.is_moto || payment.is_oct)) {
      if (payment.show_cvc_input) {
        return true;
      }
    }
  }

  return false;
};

export const shouldShowRememberCard = (payment: Payment, newCardMode: boolean): boolean => {
  if (payment.force_save_method || payment.force_tokenization) {
    return false;
  }
  if (!payment.is_apm) {
    const savedCard = hasSavedCard(payment);
    if (!savedCard && payment.recurring) {
      if (!(payment.force_save_method || payment.force_tokenization)) {
        return true;
      }
    }
    if (newCardMode && savedCard) {
      return true;
    }
  }
  return false;
};

export const getSavedCardDigits = (payment: Payment): string => payment.saved_card?.digits || '';

export const shouldRememberCard = (payment: Payment): boolean => {
  if (payment.force_tokenization && payment.original_client_id?.length) {
    return true;
  }

  if (payment.force_tokenization && payment.is_oct) {
    return true;
  }

  return !!payment.force_save_method;
};

export const getSavedCardType = (payment: Payment, newCardMode: boolean) =>
  !newCardMode ? payment.saved_card?.type || '' : '';

export const usePaymentFields = ({
  newCardMode,
}: Props): Response => {
  const { paymentDetails } = useContextDetails();
  return {
    rememberCard: shouldRememberCard(paymentDetails),
    savedCardDigits: getSavedCardDigits(paymentDetails),
    showRememberCard: shouldShowRememberCard(paymentDetails, newCardMode),
    showCvc: shouldShowCvC(paymentDetails, newCardMode, isDemoPayment(paymentDetails.hash)),
    savedCardType: getSavedCardType(paymentDetails, newCardMode),
  };
};
