import { useReducer, useEffect } from 'react';
import { isEqual } from '@ngard/tiny-isequal';

import { isDemoCardId } from 'src/demo/constants';
import { useContextDetails } from 'src/hooks/useContextDetails';
import { hasSavedCard, usePaymentFields } from 'src/hooks/usePaymentFields';
import { useCardData } from 'src/hooks/useCardData';
import { isValidCardHolderName, luhnCheck } from 'src/utils/validators';
import { useCardType } from 'src/hooks/useCardType';
import { i18n } from 'src/languages';
import { dropSpaces } from 'src/utils/string';

export enum CardFormFields {
  CARDHOLDER_NAME = 'cardholder_name',
  NUMBER = 'number',
  EXP_MONTH = 'exp_month',
  EXP_YEAR = 'exp_year',
  CSC = 'csc',
  SAVE_CARD = 'save_card',
}

type Errors = {
  [key in CardFormFields]: string;
}

export interface Values {
  cardholder_name?: string;
  // eslint-disable-next-line id-denylist
  number?: string;
  exp_month?: string;
  exp_year?: string;
  csc?: string;
  save_card?: boolean;
}

type FieldName = keyof Values;
type FieldValue = string | boolean | undefined;
type ValidatorType = (value: FieldValue) => string | undefined;
type ErrorsType = { [key in FieldName]: string };
type ReducerType<T> = React.Reducer<T, Partial<T>>;

interface Props {
  newCardMode: boolean;
}

interface Response {
  values: Values;
  errors: Errors;
  isBusy: boolean;
  handleChange(name: string, val: FieldValue): void;
  handleSubmit(): void | Promise<void> | unknown;
}

interface State {
  isSubmitted?: boolean;
  hasSubmitEvent?: boolean;
  isBusy?: boolean;
  dirtyFields?: FieldName[];
  values?: Values;
}

export const MONTH_MAX = 12;
export const MONTH_MIN = 0;
const NO_ERROR = '';
const AUTO_VALIDATE_FIELDS = [CardFormFields.CARDHOLDER_NAME];

const formatNumbers = (v: string) => v.replace(/\D/g, '');

const formatCardId = (val: string) => {
  let newValue = '';
  val = formatNumbers(val);
  for (let i = 0; i < val.length; i++) {
    if (i % 4 === 0 && i > 0) {newValue = newValue.concat(' ');}
    newValue = newValue.concat(val[i]);
  }
  return newValue;
};

const formatCardCvc = formatNumbers;

const formatMonth = (val: string) => {
  val = formatNumbers(String(val));
  const numVal = Number(val);

  if (numVal === 1) {
    return val;
  }

  if (numVal > 1 && numVal < 10) {
    return `0${numVal}`;
  }

  if (numVal > 19) {
    return String(19);
  }

  return val;
};

const getDate = () => {
  const date = new Date();
  return {
    currentYear: date.getFullYear().toString().slice(-2),
    currentMonth: date.getMonth() + 1,
  };
};

const initialFieldsState = {
  cardholder_name: '',
  // eslint-disable-next-line id-denylist
  number: '',
  exp_month: '',
  exp_year: '',
  csc: '',
  save_card: false,
};

const initialState: State = {
  isSubmitted: false,
  isBusy: false,
  dirtyFields: [],
  values: initialFieldsState,
};

const reducer = (state: State, data: Partial<State>) => ({ ...state, ...data });

export const useCardForm = ({
  newCardMode,
}: Props): Response => {
  const { t, paymentDetails } = useContextDetails();
  const {
    showCvc,
    rememberCard,
  } = usePaymentFields({ newCardMode });
  const { submitData } = useCardData({ newCardMode });
  const [state, setState] = useReducer<ReducerType<State>>(reducer, initialState);
  const { cardType, cvcMaxLength } = useCardType(String(state.values?.number));
  const isSavedCardPayment = hasSavedCard(paymentDetails) && !newCardMode;

  const isDirty = (name: FieldName) => (state.dirtyFields || []).includes(name);

  useEffect(() => {
    // eslint-disable-next-line
    if (!isEqual(initialFieldsState, state.values)) {
      setState({ values: initialFieldsState });
    }
    // eslint-disable-next-line
  }, [newCardMode]);

  const shouldValidateField = (name: FieldName) => {
    const dirty = isDirty(name);
    return AUTO_VALIDATE_FIELDS.includes(name as CardFormFields)
      ? state.isSubmitted || dirty
      : state.isSubmitted;
  };

  const validate = (name: FieldName, value: FieldValue, validator: ValidatorType) =>
    shouldValidateField(name) ? (validator(value) || NO_ERROR) : NO_ERROR;

  const validateCardHolderName = (value: FieldValue) => {
    if (isSavedCardPayment) {
      return;
    }

    if (!isValidCardHolderName(String(value))) {
      return i18n(t.ProvideName);
    }
  };

  const validateCardNumber = (value: FieldValue) => {
    if (isSavedCardPayment) {
      return;
    }
    const cleanedValue = dropSpaces(String(value));


    const acceptedCards = paymentDetails.accepted_cards;
    const lowerAcceptedCards = acceptedCards.map((element) => element.toLowerCase());

    if (!lowerAcceptedCards.includes(String(cardType))) {
      return i18n(t.ProvideValidNumber);
    }

    if (!(cleanedValue.length && (luhnCheck(cleanedValue) || isDemoCardId(cleanedValue)))) {
      return i18n(t.ProvideNumber);
    }
  };

  const validateExpMonth = (value: FieldValue) => {
    if (isSavedCardPayment) {
      return;
    }
    const date = getDate();
    const i18nValue = i18n(t.ValidDate);
    if (!value) {
      return i18nValue;
    }
    if (Number(value) > MONTH_MAX || Number(value) < MONTH_MIN) {
      return i18nValue;
    }
    if (state.values?.exp_year === date.currentYear && Number(value) < Number(date.currentMonth)) {
      return i18nValue;
    }
  };

  const validateExpYear = (value: FieldValue) => {
    if (isSavedCardPayment) {
      return;
    }
    const i18nValue = i18n(t.ValidDate);
    if (!value) {
      return i18nValue;
    }
    const date = getDate();
    if (Number(value) < Number(date.currentYear)) {
      return i18nValue;
    }
    if (Number(value) === Number(date.currentYear) && Number(state.values?.exp_month) < Number(date.currentMonth)) {
      return i18nValue;
    }
  };

  const validateCsc = (value: FieldValue) => {
    if (showCvc && !paymentDetails.cvv_optional) {
      const length = String(value).length;
      if (!length || length < cvcMaxLength) {
        return i18n(t.ProvideCVC);
      }
    }
  };

  const visibleValues: Values = {
    cardholder_name: String(state.values?.cardholder_name),
    // eslint-disable-next-line id-denylist
    number: formatCardId(state.values?.number || ''),
    exp_month: formatMonth(state.values?.exp_month || ''),
    exp_year: formatNumbers(String(state.values?.exp_year)),
    csc: formatCardCvc(state.values?.csc || ''),
    save_card: !state.values?.save_card,
  };

  const validators: { [key in FieldName]: ValidatorType} = {
    cardholder_name: validateCardHolderName,
    // eslint-disable-next-line id-denylist
    number: validateCardNumber,
    exp_month: validateExpMonth,
    exp_year: validateExpYear,
    csc: validateCsc,
    save_card: () => '',
  };

  const errors: ErrorsType = Object
    .keys(state.values || {})
    .reduce((accumulated, current) => ({
      ...accumulated,
      [current]: validate(
        current as FieldName,
        visibleValues[current as FieldName],
        validators[current as FieldName],
      ),
    }), {} as ErrorsType);

  const submissionData = {
    ...state.values,
    save_card: rememberCard ? true : !!state.values?.save_card,
  };

  const isValidForm = () => !Object.values(errors).filter((error: string) => !!error.length).length;

  const handleChange = (name: keyof Values, val: string) => {
    const newState: State = {};

    if (!isDirty(name)) {
      newState.dirtyFields = [...(state.dirtyFields || []), name];
    }

    if (val !== (state.values || initialFieldsState)[name]) {
      newState.values = { ...state.values, [name]: val };
    }

    if (Object.keys(newState).length) {
      setState(newState);
    }
  };

  useEffect(() => {
    if (state.hasSubmitEvent && state.isSubmitted && !state.isBusy) {
      if (isValidForm()) {
        setState({ isBusy: true });
        submitData(submissionData);
      } else {
        setState({ hasSubmitEvent: false });
      }
    }
  // eslint-disable-next-line
  }, [state.hasSubmitEvent]);

  const handleSubmit = () => {
    setState({
      isSubmitted: true,
      hasSubmitEvent: true,
    });
  };

  return {
    values: visibleValues,
    errors,
    isBusy: !!state.isBusy,
    handleChange,
    handleSubmit,
  };
};
