import React, {
  SetStateAction,
  Dispatch,
  ReactNode,
  useState,
  createContext,
} from 'react';
import { DIRECTIONS } from 'src/languages/language';

export type TranslationValueType = string | string[];
export interface TranslationType {
  [key: string]: TranslationValueType;
}

export type TranslationContextType = {
  translations: TranslationType
  setTranslations: Dispatch<SetStateAction<TranslationType>>
}

type UserContextProviderProps = {
  children: ReactNode
}

export const TranslationContext = createContext({} as TranslationContextType);

export const initialTranslationContextValue = {
  __loaded__: '',
  __direction__: DIRECTIONS.LTR,
};

export const TranslationContextProvider = ({ children }: UserContextProviderProps) => {
  const [translations, setTranslations] = useState<TranslationType>(initialTranslationContextValue);
  return (
    <TranslationContext.Provider value={{ translations, setTranslations }}>
      {children}
    </TranslationContext.Provider>
  );
};
