import { useRef, useEffect } from 'react';

export const useOnce = (factory: () => unknown, deps: unknown[] = []): void => {
  const shouldUse = useRef(true);

  useEffect(() => {
    if (shouldUse.current) {
      shouldUse.current = false;
      factory();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

};
