import { SdkEvent, EventCallbackType } from './event';

// Do not change!
// Taken from https://gitlab.dtte.ch/frontend/web-sdk/-/blob/main/src/lib/event/types.ts
//
export const SET_3DS_URL = 'ec3';
export const SHOW_APM_WINDOW = 'ec8';
export const HIDE_APM_WINDOW = 'ec9';
export const SHOW_PAYPAL_BACKDROP = 'ec10';
export const HIDE_PAYPAL_BACKDROP = 'ec11';

export const ON_SUCCESS = 'ec14';
export const ON_FAILURE = 'ec15';
export const ON_ERROR = 'ec16';
export const ON_TIMEOUT = 'ec20';
export const ON_ORIGIN_CHECK = 'origin:check';

export const REDIRECT_APM = 'ec17';
export const REDIRECT_ON_SUCCESS = 'ec18';
export const REDIRECT_ON_FAILURE = 'ec19';
export const REDIRECT_ON_TIMEOUT = 'ec21';
export const REDIRECT_ON_IN_PROGRESS = 'ec22';
export const SET_IFRAME_HEIGHT = 'ec23';

export enum IframeTypes {
  PAYFORM = 'payform',
  DS3 = '3ds',
  APM = 'apm',
}

const stringToLocation = (data: string): IframeTypes => {
  const location = data.split(':').at(-1);
  return location as IframeTypes;
};

interface OriginResponseType {
  location: IframeTypes;
  isSdk: boolean;
}

export class SdkClient {
  public sdkOriginId = 'origin=JSSDKv1';
  private event = new SdkEvent();
  private originCheckPeriod = 1000;

  constructor() {
    window.onmessage = (event: MessageEvent<any>) => this.event.onAction(event.data);
  }

  public get hasOrigin (): boolean {
    return window.location.search.includes(this.sdkOriginId);
  }

  private msgToSdk (eventType: string, value?: unknown): void {
    window.parent.postMessage(JSON.stringify({ eventType, value }), '*');
  }

  public set ds3Url(url: string) {
    if (this.hasOrigin) {
      this.msgToSdk(SET_3DS_URL, url);
    } else {
      window.location.href = url;
    }
  }

  public set apmUrl(url: string) {
    this.msgToSdk(SHOW_APM_WINDOW, url);
  }

  public set apmRedirectUrl(url: string) {
    this.msgToSdk(REDIRECT_APM, url);
  }

  public set fullScreen (choice: boolean) {
    this.msgToSdk(choice ? SHOW_PAYPAL_BACKDROP : HIDE_PAYPAL_BACKDROP);
  }

  public set iframeHeight (height: string) {
    this.msgToSdk(SET_IFRAME_HEIGHT, height);
  }

  public addCloseListener (name: string, callback: EventCallbackType): void {
    this.event.addCloseListener(name, callback);
  }

  public addActionListener (callback: EventCallbackType): void {
    this.event.addActionListener(callback);
  }

  public removeCloseListener (name: string): void {
    this.event.removeCloseListener(name);
  }

  public set inProgressRedirectUrl (url: string) {
    this.msgToSdk(REDIRECT_ON_IN_PROGRESS, url);
  }

  public set successUrl (url: string) {
    this.msgToSdk(ON_SUCCESS, url);
  }

  public set successRedirectUrl (url: string) {
    this.msgToSdk(REDIRECT_ON_SUCCESS, url);
  }

  public set failureUrl (url: string) {
    this.msgToSdk(ON_FAILURE, url);
  }

  public set failureRedirectUrl (url: string) {
    this.msgToSdk(REDIRECT_ON_FAILURE, url);
  }

  public set timeoutUrl (url: string) {
    this.msgToSdk(ON_TIMEOUT, url);
  }

  public set timeoutRedirectUrl (url: string) {
    this.msgToSdk(REDIRECT_ON_TIMEOUT, url);
  }

  public async checkOrigin () {
    const { event, msgToSdk, originCheckPeriod } = this;
    return new Promise((resolve: (data: OriginResponseType) => void) => {
      const handleFinish = (data: OriginResponseType) => {
        resolve(data);
        event.removeOriginResponseListener();
      };
      event.addOriginResponseListener((name) => handleFinish({ location: stringToLocation(name || ''), isSdk: true}));
      msgToSdk(ON_ORIGIN_CHECK);
      window.setTimeout(() => handleFinish({ location: IframeTypes.PAYFORM, isSdk: false }), originCheckPeriod);
    });
  }

  public notifyError (data: string) {
    this.msgToSdk(ON_ERROR, data);
  }

}
