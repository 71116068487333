import React, {
  SetStateAction,
  Dispatch,
  ReactNode,
  useState,
  createContext,
} from 'react';

import { statusConfigInitialState } from 'src/factories/statusConfigInitialState';
import { StatusConfigType } from 'types/statusConfigType';

type StatusConfigContextType = {
  statusConfig: StatusConfigType
  setStatusConfig: Dispatch<SetStateAction<StatusConfigType>>
}

type StatusConfigContextProviderProps = {
  children: ReactNode
}

export const StatusConfigContext = createContext({} as StatusConfigContextType);

export const StatusConfigContextProvider = ({ children }: StatusConfigContextProviderProps) => {
  const [statusConfig, setStatusConfig] = useState<StatusConfigType>(statusConfigInitialState());
  return (
    <StatusConfigContext.Provider value={{ statusConfig, setStatusConfig }}>
      {children}
    </StatusConfigContext.Provider>
  );
};
