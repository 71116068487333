import React, { PropsWithChildren } from 'react';
import * as Sentry from '@sentry/react';

import { SENTRY_ENABLED } from './sentry';
import { ErrorBoundary, withErrorPage } from 'src/loggers/ErrorBoundary';

export const Logger: React.FC<PropsWithChildren> = ({ children }) =>
  SENTRY_ENABLED
    ? (
      <Sentry.ErrorBoundary fallback={({ error }) => withErrorPage(error)}>
        {children}
      </Sentry.ErrorBoundary>
    )
    : (
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    );
