import { useState } from 'react';

interface Response {
  logError(error?: Error): void;
}

export const useError = (): Response => {
  // Errors in async code must be made visible to error boundary
  // eslint-disable-next-line @typescript-eslint/naming-convention,react/hook-use-state
  const catchError = useState<Error | undefined>(undefined);

  return {
    logError: (error) => {
      if (error) {
        catchError[1](() => { throw error; });
      }
    },
  };
};
