import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Logger } from './loggers/Logger';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Logger>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Logger>
  </React.StrictMode>,
);
