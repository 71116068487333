import Cookies from 'js-cookie';
import { BASE_OPTIONS } from './SessionType';

export class ActivePaymentMethod {
  static getName = (pid: string) => `apm-${pid}`;

  static get = (pid: string) => {
    const cookieValue = Cookies.get(ActivePaymentMethod.getName(pid));
    return cookieValue ? cookieValue : undefined;
  };

  static set = (pid: string, method: string) =>
    Cookies.set(ActivePaymentMethod.getName(pid), String(method), {
      expires: 1, // 1 = 1 day
      ...BASE_OPTIONS,
    });

  static reset = (pid: string) => {
    Cookies.remove(ActivePaymentMethod.getName(pid));
  };
}
