
export class BackendError extends Error {
  httpCode?: number;

  constructor(message: string, httpCode: number) {
    super(message);

    this.name = `BackendError (${httpCode})`;
    this.httpCode = httpCode;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BackendError);
    }
  }
}

