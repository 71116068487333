import Cookies from 'js-cookie';
import { BASE_OPTIONS } from './SessionType';

export class ApmWaitingTime {

  static getName = (pid: string) => `tl-${pid}`;

  static get = (pid: string) => {
    const cookieValue = Cookies.get(ApmWaitingTime.getName(pid));
    return cookieValue ? Number(cookieValue) : undefined;
  };

  static set = (pid: string, seconds: number) =>
    Cookies.set(ApmWaitingTime.getName(pid), String(seconds), {
      expires: 1,
      ...BASE_OPTIONS,
    });

  static reset = (pid: string) => {
    Cookies.remove(ApmWaitingTime.getName(pid));
  };

}
