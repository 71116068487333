import { GuiLanguage } from 'src/utils/cookies';

const FE_LANGUAGE_DETECTION_ENABLE = false;

export enum SUPPORTED_LANGUAGES {
  EN = 'en',
  LV = 'lv',
  RU = 'ru',
  IL = 'he',
}

const RTL_LANGUAGES = [
  SUPPORTED_LANGUAGES.IL,
];

export enum DIRECTIONS {
  RTL = 'rtl',
  LTR = 'ltr',
}

export class Language {

  default = SUPPORTED_LANGUAGES.EN;
  supportedCodes = Object.values(SUPPORTED_LANGUAGES);

  isSupported (lang: string) {
    return Object.values(SUPPORTED_LANGUAGES).includes(lang as SUPPORTED_LANGUAGES);
  }

  get fromVisitor () {
    try {
      const lang = navigator.language.split('-')[0].toLowerCase();
      return this.isSupported(lang) ? lang : undefined;
    } catch {
      return undefined;
    }
  }

  set toStorage (lang: SUPPORTED_LANGUAGES | string) {
    if (this.isSupported(lang)) {
      GuiLanguage.set(lang);
    }
  }

  get fromStorage () {
    const lang = GuiLanguage.get();
    return this.isSupported(lang || '') ? lang : undefined;
  }

  getDirection (lang: SUPPORTED_LANGUAGES) {
    return RTL_LANGUAGES.includes(lang) ? DIRECTIONS.RTL : DIRECTIONS.LTR;
  }

  getLanguage (lang: SUPPORTED_LANGUAGES) {
    const hasDefault = this.detected === this.default;
    return hasDefault ? lang : this.detected;
  }

  get detected () {
    if (!FE_LANGUAGE_DETECTION_ENABLE) {
      return this.default;
    }

    const storedLang = this.fromStorage;
    if (storedLang) {
      return storedLang;
    }

    const visitorLang = this.fromVisitor;
    if (visitorLang) {
      return visitorLang;
    }

    return this.default;
  }
}

export const language = new Language();
