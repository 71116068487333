import React from 'react';

interface Props {
  message?: string
}

export const Spinner: React.FC<Props> = ({ message }) => (
  <div className="spinner">
    <span className="loader" data-testid="loader" />
    {message && (<p>{message}</p>)}
  </div>
);