import React, { useState, useEffect } from 'react';

import { TranslationType, initialTranslationContextValue } from 'src/context/TranslationContext';
import { markAsLoaded } from 'src/components/payment/LanguageSwitcher';
import { retrieveTranslation, i18n } from 'src/languages';
import { Spinner } from 'src/components/layouts/Spinner';
import { Cancelled1 } from 'src/assets/Icons';

export const InternalError: React.FC = () => {
  const [t, setT] = useState<TranslationType>(initialTranslationContextValue);

  const setTranslation = async () => {
    const i18nItems = await retrieveTranslation();
    setT(markAsLoaded(i18nItems));
  };

  useEffect(() => {
    setTranslation();
    // eslint-disable-next-line
  },[]);

  document.title = !!t.__loaded__ ? i18n(t.InternalError) : '...';

  return !!t.__loaded__ ? (
    <div className="errorWrapper">
      <div className="error">
        <Cancelled1 />
        <h1>{t.InternalError}</h1>
        <p>{t.InternalErrorSorry}</p>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};
