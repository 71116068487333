import { useContext } from 'react';
import {
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { backend } from 'src/backend';
import {
  DEMO_CARD_PANS,
  DEMO_FAILURE_PAYMENT_ID,
  DEMO_SUCCESS_PAYMENT_ID,
  DEMO_SUCCESS_SCS,
} from 'src/demo/constants';
import { isDemoPayment } from 'src/demo/utils';
import { Values } from 'src/hooks/useCardForm';
import { useContextDetails } from 'src/hooks/useContextDetails';
import { useError } from 'src/hooks/useError';
import {
  Payment,
  onSuccessRedirect,
  getRequestOrigin,
} from 'src/hooks/usePayment';
import { dropSpaces } from 'src/utils/string';
import { ThreeDsContext } from 'src/context/ThreeDsContext';
import { ConvertedFormTypes } from 'src/types/convertedFormTypes';

interface Props {
  newCardMode: boolean;
}

interface Response {
  submitData(data: Values): Promise<void>
}

const asFormData = (data: Values, hasSavedCard: boolean, cvc: boolean) => {
  let convertedFormData:ConvertedFormTypes = {
    ...data,
    // eslint-disable-next-line id-denylist
    number: dropSpaces(data.number || ''),
  };

  if (hasSavedCard && cvc) {
    convertedFormData = {
      csc: data.csc,
      charge_saved_card: true,
    };
  }

  if (hasSavedCard && !cvc) {
    convertedFormData = {
      charge_saved_card: true,
    };
  }

  return convertedFormData;
};

export const useCardData = ({ newCardMode }: Props):Response => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { paymentDetails: { hash, show_cvc_input }, t } = useContextDetails();
  const navigate = useNavigate();
  const { pid = '' } = useParams();
  const isDemoMode = isDemoPayment(pid);
  const [ searchParams ] = useSearchParams();
  const { logError: onFail } = useError();
  const threeDsContext = useContext(ThreeDsContext);

  const onSuccess = (response: Payment): void => {
    if (response.redirect_url) {
      onSuccessRedirect(!!response.check_3ds, response.redirect_url, threeDsContext.setUrl);
    }
  };

  const routeLang = searchParams.get('lang');
  const submitData = async (data: Values) => {
    if (!isDemoMode) {
      await backend.submitCard<Payment>({
        pid: hash,
        payload: asFormData(data, !newCardMode, show_cvc_input),
        apiProps: {
          origin: getRequestOrigin(),
          language: t.__language__ as string,
        },
        onSuccess,
        onFail,
      });
    } else {
      const cardId = dropSpaces(data.number || '');
      const paymentId = DEMO_CARD_PANS.includes(cardId) && data.csc === DEMO_SUCCESS_SCS
        ? DEMO_SUCCESS_PAYMENT_ID
        : DEMO_FAILURE_PAYMENT_ID;
      navigate(`/client/payment/${paymentId}/${routeLang ? `?lang=${routeLang}` : ''}`);
    }
  };

  return {
    submitData,
  };
};
