import React, { useState, useEffect } from 'react';

import { TranslationType, initialTranslationContextValue } from 'src/context/TranslationContext';
import { markAsLoaded } from 'src/components/payment/LanguageSwitcher';
import { retrieveTranslation, i18n } from 'src/languages';
import { Spinner } from 'src/components/layouts/Spinner';

export const NotFound: React.FC = () => {
  const [t, setT] = useState<TranslationType>(initialTranslationContextValue);

  const setTranslation = async () => {
    const i18nItems = await retrieveTranslation();
    setT(markAsLoaded(i18nItems));
  };

  useEffect(() => {
    setTranslation();
    // eslint-disable-next-line
  },[]);

  document.title = i18n(t.PageNotFound);

  return !!t.__loaded__ ? (
    <div className="errorWrapper">
      <div className="error">
        <span>404</span>
        <h1>{t.PageNotFound}</h1>
        <p>{t.WeAreSorry}</p>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};
