export const replace = (template: string, what: string, to: string) => {
  try {
    // String.prototype.replaceAll() method was added in ES2021/ES12.
    return template.replaceAll(what, to);
  } catch {
    return template.replace(new RegExp(`/${what}/g`), to);
  }
};

export const dropSpaces = (template: string) => replace(template, ' ', '');
