import Cookies, { CookieAttributes } from 'js-cookie';

export const BASE_OPTIONS: CookieAttributes = {
  sameSite: 'strict',
  secure: location.protocol.includes('https'),
  path: '/',
  domain: window.location.hostname,
};

export class SessionType {
  static isSdk = '1';

  static getName = (pid: string) => `st-${pid}`;

  static get = (pid: string) => Cookies.get(SessionType.getName(pid));

  static set = (pid: string, value: string) =>
    Cookies.set(SessionType.getName(pid), value, { expires: 1, ...BASE_OPTIONS });

  static reset = (pid: string) => {
    Cookies.remove(SessionType.getName(pid));
  };
}
