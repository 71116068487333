export const removeLoading = () => {
  const body = document.body;
  if (body) {
    body.classList.remove('loading');
  }

  document.title = '';

  const spinner = document.getElementById('spinner');
  spinner?.remove();
};
