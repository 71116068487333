import React, { ErrorInfo, PropsWithChildren } from 'react';

import { sdk } from 'src/sdk';
import { isIframe } from 'src/utils/iframe';
import { BackendError } from 'src/backend/error';
import { NotFound } from 'src/components/errors/NotFound';
import { InternalError } from 'src/components/errors/InternalError';

interface State {
  hasError: boolean;
  errorInfo?: ErrorInfo;
  error?: BackendError;
}

export const withErrorPage = (error: BackendError) => {
  if (isIframe && sdk.hasOrigin) {
    sdk.notifyError(String(error));
  }

  if (error.httpCode === 404) {
    return (
      <NotFound />
    );
  }

  return (
    <InternalError />
  );
};

export class ErrorBoundary extends React.Component<PropsWithChildren, State> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error: ErrorInfo) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorInfo: error };
  }

  componentDidCatch(error: BackendError, errorInfo: ErrorInfo) {
    this.setState({ hasError: true, error, errorInfo });
  }

  render() {
    const error = this.state.error;
    return !!error ? withErrorPage(error) : this.props.children;
  }
}
