import React, {
  SetStateAction,
  Dispatch,
  ReactNode,
  useState,
  createContext,
} from 'react';

type ThreeDsContextType = {
  url: string
  setUrl: Dispatch<SetStateAction<string>>
}

type ThreeDsProps = {
  children: ReactNode
}

export const ThreeDsContext = createContext({} as ThreeDsContextType);

export const ThreeDsContextProvider = ({ children }: ThreeDsProps) => {
  const [url, setUrl] = useState<string>('');
  return (
    <ThreeDsContext.Provider value={{ url, setUrl }}>
      {children}
    </ThreeDsContext.Provider>
  );
};
