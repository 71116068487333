import { useReducer } from 'react';

import {
  backend,
  Response300,
  containsRedirect,
} from 'src/backend';
import { useError } from 'src/hooks/useError';
import { PaymentTypes } from 'src/components/containers/StatusContainer';
import { GATE_API_HOST } from 'src/constants/environment';

interface Props {
  pid: string,
  ptype: string,
  redirectTo: string|null,
}

export interface Country {
  id: string,
  display_name: string,
}

export enum FieldNameType {
  EMAIL = 'email',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  PERSONAL_CODE = 'personal_code',
  COMPANY_NAME = 'company_name',
  LEGAL_NAME = 'legal_name',
  LEGAL_ADDRESS = 'legal_address',
  REGISTRATION_ID = 'registration_nr',
  VAT_PAYER_ID = 'vat_payer_nr',
  COUNTRY = 'country',
  STATE = 'state',
  CITY = 'city',
  ZIP_CODE = 'zip_code',
  IBAN = 'iban',
  SWITF = 'swift',
  PHONE = 'phone',
}

enum StatusResponse {
  SUCCESS = 1,
}

export interface MissingFields {
  missing_fields: FieldNameType[],
  countries: Country[],
  user_country: string,
}

export interface MissingFieldsUpdateResponse {
  status: number;
  errors?: {
    [key: string]: string[]
  }
  url?: string;
}

export interface ChargeFieldsSuccessResponse {
  status: number,
  url: string,
}

export type MissingFieldsUpdate = MissingFieldsUpdateResponse | Response300;
export type PayloadType = { [key: string]: any }

type ReducerType<T> = React.Reducer<T, Partial<T>>;

interface State {
  requiredFields?: MissingFields;
  updateErrors?: MissingFieldsUpdate;
  isBusy?: boolean;
}

interface Response extends State {
  requiredFields: MissingFields;
  updateErrors: MissingFieldsUpdate;
  isBusy: boolean;
  fetchData(): Promise<void>;
  sendData(payload: PayloadType): Promise<void>;
}

const initialState: State = {
  requiredFields: {
    'missing_fields': [],
    'countries': [],
    'user_country': '',
  },
  updateErrors: {
    'status': 0,
    'errors': {},
    'url': '',
  },
  isBusy: false,
};

const reducer = (state: State, data: Partial<State>) => ({ ...state, ...data });

export const useRequiredFields = ({ pid, ptype, redirectTo }: Props): Response => {
  const [state, setState] = useReducer<ReducerType<State>>(reducer, initialState);
  const { logError } = useError();

  const onFetchSuccess = (data: MissingFields) => {
    setState({
      isBusy: false,
      requiredFields: data,
    });
  };

  const onFail = (error: Error) => {
    setState({ isBusy: false });
    logError(error);
  };

  const onUpdateSuccess = (data: MissingFieldsUpdate) => {
    if (redirectTo === PaymentTypes.Payment) {
      window.location.href = `${GATE_API_HOST}/client/payment/${pid}`;
      return;
    }

    if (containsRedirect(data)) {
      const url = (data as Response300).location;
      window.location.href = url;
    } else {
      setState({
        isBusy: false,
        requiredFields: data as unknown as MissingFields,
      });
    }
  };

  const onChargeUpdateSuccess = (data: ChargeFieldsSuccessResponse) => {
    if (data.status === StatusResponse.SUCCESS) {
      window.location.href = data.url;
    } else {
      setState({
        isBusy: false,
        requiredFields: data as unknown as MissingFields,
      });
    }
  };

  const fetchData = async () => {
    if ([PaymentTypes.Invoice, PaymentTypes.Charge].includes(ptype as PaymentTypes)) {
      setState({ isBusy: true });
      return backend.requiredFields<MissingFields>({
        pid,
        ptype: ptype as PaymentTypes,
        onSuccess: onFetchSuccess,
        onFail,
      });
    }
  };

  const sendData = async (payload: PayloadType) => {
    if (ptype === PaymentTypes.Invoice) {
      setState({ isBusy: true });
      await backend.requiredFields<MissingFieldsUpdate>({
        pid,
        payload,
        ptype,
        onSuccess: onUpdateSuccess,
        onFail,
      });
    }
    if (ptype === PaymentTypes.Charge) {
      setState({ isBusy: true });
      await backend.requiredFields<ChargeFieldsSuccessResponse>({
        pid,
        ptype,
        payload,
        onSuccess: onChargeUpdateSuccess,
        onFail,
      });
    }
  };

  return {
    requiredFields: (state.requiredFields || initialState.requiredFields) as MissingFields,
    updateErrors: (state.updateErrors || initialState.updateErrors) as MissingFieldsUpdate,
    isBusy: !!state.isBusy,
    fetchData,
    sendData,
  };
};
