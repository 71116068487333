import React, {
  SetStateAction,
  Dispatch,
  ReactNode,
  useState,
  createContext,
} from 'react';
import formStyles from 'src/constants/styles.json';

export interface StyleType {
  form: object;
  input: object;
  label: object;
  button: object;
}

type StyleContextType = {
  style: StyleType
  setStyle: Dispatch<SetStateAction<StyleType>>
}

type StyleContextProviderProps = {
  children: ReactNode
}

export const StyleContext = createContext({} as StyleContextType);

export const StyleContextProvider = ({ children }: StyleContextProviderProps) => {
  const [style, setStyle] = useState<StyleType>(formStyles);
  return (
    <StyleContext.Provider value={{ style, setStyle }}>
      {children}
    </StyleContext.Provider>
  );
};
