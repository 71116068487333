import {
  ON_FAILURE,
  ON_SUCCESS,
  ON_TIMEOUT,
  REDIRECT_ON_FAILURE,
  REDIRECT_ON_SUCCESS,
  REDIRECT_ON_TIMEOUT,
  ON_ORIGIN_CHECK,
} from './client';

export interface EventData {
  eventType?: string;
  value?: string;
}

export type EventCallbackType = (name?: string, value?: string) => void;

interface EventListenerType {
  name: string,
  eventType: string;
  callback: EventCallbackType;
}

// Do not change!
// Taken from https://gitlab.dtte.ch/frontend/web-sdk/-/blob/main/src/lib/event/types.ts

// ts-prune-ignore-next
const HIDE_APM_WINDOW = 'ec9'; // causes problems im imported
const ORIGIN_FROM_SDK_PAYFORM = 'origin:sdk:payform';
const ORIGIN_FROM_SDK_APM = 'origin:sdk:apm';
export const ORIGIN_FROM_SDK_3DS = 'origin:sdk:3ds';

export class SdkEvent {
  private listeners: EventListenerType[] = [];
  private eventTypes = {
    originRequest: [ON_ORIGIN_CHECK],
    origin: [ORIGIN_FROM_SDK_PAYFORM, ORIGIN_FROM_SDK_APM, ORIGIN_FROM_SDK_3DS],
    action: [ON_FAILURE, ON_SUCCESS, ON_TIMEOUT],
    redirectAction: [
      REDIRECT_ON_FAILURE,
      REDIRECT_ON_SUCCESS,
      REDIRECT_ON_TIMEOUT,
    ],
    close: [HIDE_APM_WINDOW],
  };

  public decompilePayload (payload: string): EventData {
    try {
      return JSON.parse(payload) as EventData;
    } catch (e) {
      return {};
    }
  }

  public onAction (data: string): void {
    const payload = this.decompilePayload(data);
    this.listeners.forEach(listener => {
      if (payload.eventType === listener.eventType) {
        listener.callback(payload?.eventType, payload?.value);
      }
    });
  }

  public addCloseListener (name: string, callback: EventCallbackType) {
    this.listeners.push({ name, eventType: HIDE_APM_WINDOW, callback });
    return this;
  }

  public removeCloseListener (name: string) {
    this.listeners = this.listeners.filter(item => item.name !== name && item.eventType !== HIDE_APM_WINDOW);
    return this;
  }

  private removeListeners(events: string[]) {
    this.listeners = this.listeners
      .filter(item => (!events.includes(item.name) && !events.includes(item.eventType)));
    return this;
  }

  public addOriginRequestListener (callback: EventCallbackType) {
    this.eventTypes.originRequest.forEach(eventType => this.listeners.push({ name: eventType, eventType, callback }));
    return this;
  }

  public removeOriginRequestListener () {
    this.removeListeners(this.eventTypes.originRequest);
    return this;
  }

  public addOriginResponseListener (callback: EventCallbackType) {
    this.eventTypes.origin.forEach(eventType => this.listeners.push({ name: eventType, eventType, callback }));
    return this;
  }

  public removeOriginResponseListener () {
    this.removeListeners(this.eventTypes.origin);
    return this;
  }

  public addActionListener (callback: EventCallbackType) {
    this.eventTypes.action.forEach(eventType => this.listeners.push({ name: eventType, eventType, callback }));
    return this;
  }

  public removeActionListener () {
    this.removeListeners(this.eventTypes.action);
    return this;
  }

  public addRedirectActionListener (callback: EventCallbackType) {
    this.eventTypes.redirectAction.forEach(eventType => this.listeners.push({ name: eventType, eventType, callback }));
    return this;
  }

  public removeRedirectActionListener () {
    this.removeListeners(this.eventTypes.redirectAction);
    return this;
  }
}
