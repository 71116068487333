import React, {
  SetStateAction,
  Dispatch,
  ReactNode,
  useState,
  createContext,
} from 'react';

import { MissingFields } from 'src/hooks/useRequiredFields';

export const initialState = {
  'missing_fields': [],
  'countries': [],
  'user_country': '',
};

type FieldContextType = {
  fields: MissingFields
  setFields: Dispatch<SetStateAction<MissingFields>>
}

type FieldsContextProviderProps = {
  children: ReactNode
}

export const FieldsContext = createContext({} as FieldContextType);

export const FieldsContextProvider = ({ children }: FieldsContextProviderProps) => {
  const [fields, setFields] = useState<MissingFields>(initialState);
  return (
    <FieldsContext.Provider value={{ fields, setFields }}>
      {children}
    </FieldsContext.Provider>
  );
};
