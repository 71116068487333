// Card number integrity validator
// Scraped out of jQuery Credit Card Validator 1.2
// Copyright 2012 Pawel Decowski
export const luhnCheck = (number_: string) => {
  let digit;
  let k;
  let len1;
  let n;
  let sum = 0;
  const ref1 = number_.split('').reverse();
  for (n = k = 0, len1 = ref1.length; k < len1; n = ++k) {
    digit = ref1[n];
    digit = +digit;
    if (n % 2) {
      digit *= 2;
      if (digit < 10) {
        sum += digit;
      } else {
        sum += digit - 9;
      }
    } else {
      sum += digit;
    }
  }
  return sum % 10 === 0;
};

// eslint-disable-next-line max-len
const regex = '(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35\\d{3})\\d{11})';
const cardNameDefaultRE = new RegExp(regex);
const cardNameRE = /^[a-zA-Z0-9\ \.\-\']+$/;

export const isValidCardHolderName = (value: string): boolean => {
  const cleaned = value.toUpperCase().replace(/[\s\-]+/g, '');
  if (!cleaned.length) {
    return false;
  }
  return !(!cardNameRE.test(cleaned) || cardNameDefaultRE.test(cleaned));
};

export const isValidUUID = (uuid: string): boolean => {
  const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return uuidRegex.test(uuid);
};
