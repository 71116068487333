/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';

import { CardFormFields } from 'src/hooks/useCardForm';
import { FieldNameType } from 'src/hooks/useRequiredFields';

import {
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  SENTRY_RELEASE,
} from 'src/constants/environment';

export const SENTRY_ENABLED = SENTRY_DSN && SENTRY_RELEASE && SENTRY_ENVIRONMENT;
const IS_PRODUCTION = SENTRY_ENVIRONMENT.toLowerCase().includes('production');

const CONSOLE_ERRORS = IS_PRODUCTION ? ['error'] : ['warn', 'error'];

type ContextType = { [key: string]: unknown };

const cleanObject = (obj: ContextType): object => {

  const SENSITIVE_FIELDS = [
    ...Object.values(CardFormFields),
    ...Object.values(FieldNameType),
  ] as string[];

  for (const key in obj) {
    if (SENSITIVE_FIELDS.includes(key)) {
      obj[key] = '***';
    } else if (Array.isArray(obj[key])) {
      obj[key] = (obj[key] as unknown[]).map(item => cleanObject(item as ContextType));
    } else if (typeof obj[key] === 'object') {
      obj[key] = cleanObject(obj[key] as ContextType);
    }
  }
  return obj;
};

// Browser demo - https://request-cancellation-test.vercel.app/
// Details - https://stackoverflow.com/a/70452078/1262886

const REQUEST_CANCELLATION_ERRORS = [
  'TypeError: Failed to fetch',
  'TypeError: NetworkError when attempting to fetch resource.',
  'TypeError: cancelled',
];

const APPLE_MAIL_ERRORS = [
  'ReferenceError: Can\'t find variable: initPaymentFlow',
];

const IGNORE_ERRORS = [
  /^setJwt$/,
  /^showWidget$/,
  /^setContainerStyle$/,
  /^setHostConfig$/,
];

const initSentry = (browserId: string) => {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: SENTRY_RELEASE,
    environment: SENTRY_ENVIRONMENT,
    integrations: [new BrowserTracing(), new CaptureConsole({ levels: CONSOLE_ERRORS })],
    tracesSampleRate: IS_PRODUCTION ? 0 : 1,
    beforeSend: (event) => {
      try {
        return cleanObject(event as unknown as ContextType);
      } catch (err) {
        return event;
      }
    },
    ignoreErrors: [
      ...REQUEST_CANCELLATION_ERRORS,
      ...APPLE_MAIL_ERRORS,
      ...IGNORE_ERRORS,
    ],
    initialScope: {
      user: {
        id: browserId,
      },
    },
  });
};

if (SENTRY_ENABLED) {
  getCurrentBrowserFingerPrint().then(initSentry);
}

export const log = (key: string, value: string | number | boolean): void => {
  if (SENTRY_ENABLED) {
    Sentry.setTag(key, value);
  }
};
