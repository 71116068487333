import { useContext } from 'react';

import { StatusConfigContext } from 'src/context/StatusConfigContext';
import { TranslationContext } from 'src/context/TranslationContext';
import { PaymentContext } from 'src/context/PaymentContext';
import { StyleContext } from 'src/context/StyleContext';
import { ThreeDsContext } from 'src/context/ThreeDsContext';
import { VisitorContext } from 'src/context/VisitorContext';

export const useContextDetails = () => {
  const t = useContext(TranslationContext).translations;
  const paymentDetails = useContext(PaymentContext).payment;
  const formStyles = useContext(StyleContext).style;
  const statusConfig = useContext(StatusConfigContext).statusConfig;
  const threeDs = useContext(ThreeDsContext).url;
  const visitor = useContext(VisitorContext).visitor;

  return {
    t,
    paymentDetails,
    formStyles,
    statusConfig,
    threeDs,
    visitor,
  };
};
