import { GATE_API_HOST } from 'src/constants/environment';
import { PaymentTypes } from 'src/components/containers/StatusContainer';
import fetchBuilder from 'fetch-retry';

import { BackendError } from './error';

const fetchWithRetry = fetchBuilder(window.fetch);

type PayloadType = {[key: string]: any};

export interface Response300 {
  status: number;
  location: string;
}

interface ApiCallProps {
  url: string;
  payload?: PayloadType;
  update?: boolean;
  jsonRequest?: boolean;
  formRequest?: boolean;
}

interface ApiUrlProps {
  hostname?: string,
  pathname?: string,
  query?: { [key: string]: string},
}

interface MethodProps<T>{
  pid: string,
  ptype?: PaymentTypes;
  payload?: {[key: string]: any};
  apiProps?: ApiProps;
  onSuccess: (value: T) => void | PromiseLike<void>;
  onFail: (error: Error) => void;
}

enum HttpMethods {
  GET = 'GET',
  POST = 'POST',
}

export enum RequestOrigin {
  FULLPAGE = 'fullpage',
  IFRAME = 'iframe',
  SDK = 'sdk',
}

// Added query to API endpoint
interface ApiProps {
  origin: RequestOrigin,
  language: string,
}

// Opaque redirects have status 0
const isRedirect = (status: number) => (status >= 300 && status < 400) || status === 0;

const isObject = (variable: unknown): boolean =>
  typeof variable === 'object' &&
  variable !== null;

export const containsRedirect = (data: unknown): boolean =>
  isObject(data) &&
  'location' in (data as object) &&
  'status' in (data as object);

class BackendClient {
  private gateHost: string;

  constructor(gateHost: string) {
    this.gateHost = gateHost;
  }

  private async apiCall({
    url,
    update = false,
    jsonRequest = false,
    formRequest = false,
    payload = {},
  }: ApiCallProps) {
    const headers: {[key: string]: string } = {};
    if (jsonRequest) {
      headers['Content-Type'] = 'application/json';
    }

    const formatBody = (payLoad: PayloadType) => {
      if (jsonRequest) {
        return JSON.stringify(payload);
      }

      if (formRequest) {
        const formData = new FormData();
        for (const [key, value] of Object.entries(payLoad)) {
          formData.append(key, String(value));
        }
        return formData;
      }

      return payLoad;
    };

    const body = update ? formatBody(payload) : undefined;

    const handleInitialResponse = async (response: Response): Promise<Response> => {

      // 200
      if (response.ok) {
        return response;
      }

      // 300
      // Form submission etc. requests return direct redirects.
      // Translating them to JSON - Response300
      if (isRedirect(response.status)) {
        const body300 = JSON.stringify({
          status: response.status,
          location: response.headers.get('location') || response.url,
        });

        const options300 = {
          status: 200,
        };

        return new Response(body300, options300);
      }

      // 400 - throw validation error
      const data = await response.text();
      throw new BackendError(data, response.status);
    };

    const handleConnectionError = (error: BackendError): never => {
      throw new BackendError(error.message, error.httpCode || 0);
    };

    const fetchOptions = {
      redirect: 'manual' as RequestRedirect,
      method: update ? HttpMethods.POST : HttpMethods.GET,
      body: body as BodyInit,
      headers,
      retries: 5,
      retryDelay: (attempt: number) => Math.pow(2, attempt) * 1000,
      retryOn: [502, 503],
    };

    return fetchWithRetry(url, fetchOptions)
      .then(handleInitialResponse)
      .catch(handleConnectionError);
  }

  private apiUrl = ({
    hostname = this.gateHost,
    pathname = '',
    query = {},
  }: ApiUrlProps): string => {
    const url = new URL(hostname);
    url.pathname = pathname;

    if (Object.keys(query).length) {
      const params = new URLSearchParams(query);
      url.search = params.toString();
    }

    return url.toString();
  };

  public async getPayFormConfig<T>({
    pid,
    apiProps,
    onSuccess,
    onFail,
  }: MethodProps<T>) {
    return this.apiCall({
      url: this.apiUrl({
        pathname: `/client/payment/${pid}/pay_form_config/`,
        query: {
          ...apiProps,
        },
      }),
    })
      .then(async (response: Response) => response.json())
      .then(onSuccess)
      .catch(onFail);
  }

  public async submitCard<T>({
    pid,
    payload,
    apiProps,
    onSuccess,
    onFail,
  }: MethodProps<T>) {
    return this.apiCall({
      url: this.apiUrl({
        pathname: `/client/payment/${pid}/pay_form/`,
        query: {
          ...apiProps,
        },
      }),
      update: true,
      jsonRequest: true,
      payload,
    }).then(async response => response.json())
      .then(onSuccess)
      .catch(onFail);
  }

  public async getPaymentConfig<T>({
    pid,
    ptype = PaymentTypes.Payment,
    apiProps,
    onSuccess,
    onFail,
  }: MethodProps<T>) {
    return this.apiCall({
      url: this.apiUrl({
        pathname: `/client/${ptype}/${pid}/config/`,
        query: {
          ...apiProps,
        },
      }),
    })
      .then(async (response: Response) => response.json())
      .then(onSuccess)
      .catch(onFail);
  }

  public async apmAction<T>({
    pid,
    onSuccess,
    onFail,
  }: MethodProps<T>) {
    return this.apiCall({
      url: pid,
      update: true,
      jsonRequest: true,
    })
      .then(async (response: Response) => response.json())
      .then(onSuccess)
      .catch(onFail);
  }

  public async requiredFields<T>({
    pid,
    ptype = PaymentTypes.Invoice,
    payload = {},
    onSuccess,
    onFail,
  }: MethodProps<T>) {
    const hasPayload = !!Object.keys(payload).length;
    const apiSuffix = hasPayload ? 'payment/details_request' : 'client_missing_fields';
    return this.apiCall({
      url: this.apiUrl({
        pathname: `/client/${ptype}/${pid}/${apiSuffix}/`,
      }),
      update: hasPayload,
      formRequest: hasPayload,
      payload,
    })
      .then(async (response: Response) => response.json())
      .then(onSuccess)
      .catch(onFail);
  }

  public async getPaymentStatus<T>({
    pid,
    ptype = PaymentTypes.Payment,
    apiProps,
    onSuccess,
    onFail,
  }: MethodProps<T>) {
    return this.apiCall({
      url: this.apiUrl({
        pathname: `/client/${ptype}/${pid}/progress/`,
        query: {
          return_json: 'true',
          ...apiProps,
        },
      }),
    })
      .then(async (response: Response) => response.json())
      .then(onSuccess)
      .catch(onFail);
  }

  public async rejectPayment<T>({
    pid,
    payload,
    onSuccess,
    onFail,
  }: MethodProps<T>) {
    return this.apiCall({
      url: this.apiUrl({
        pathname: `/client/invoice/${pid}/reject/`,
      }),
      update: true,
      jsonRequest: true,
      payload,
    })
      .then(async (response: Response) => response.json())
      .then(onSuccess)
      .catch(onFail);
  }
}

export const backend = new BackendClient(GATE_API_HOST);
