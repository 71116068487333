import Cookies from 'js-cookie';
import { BASE_OPTIONS } from './SessionType';

export class GuiLanguage {
  static Name = 'gui_lang';

  static get = () => {
    const cookieValue = Cookies.get(GuiLanguage.Name);
    return cookieValue ? cookieValue : undefined;
  };

  static set = (seconds: string) =>
    Cookies.set(GuiLanguage.Name, String(seconds), {
      expires: 1, // 1 = 1 day
      ...BASE_OPTIONS,
    });

  static reset = () => {
    Cookies.remove(GuiLanguage.Name);
  };
}
